@import "mixins/fadeInTranslateUpScaleUp";

.Paragraph {
    @include fadeInTranslateUpScaleUpMixin;
    position: relative;
    width: 100%;
    max-width: 700px;
    font-size: 1.5rem;
    padding: 1rem;
    margin-top: 2rem;
}