.Quote__content {
    width: 85%;

    &__quote {
        margin: 0.75rem 0;
        font-size: 1.75rem;
        text-align: justify;
        text-align-last: center;
    }

    &__cite {
        display: block;
        font-style: normal;
        font-size: 1.1rem;
        font-weight: 700;
        text-align: center;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}