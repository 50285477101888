@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import "mixins/keyframes";

@include keyframesMixin;

*, *::before, *::after {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    box-sizing: border-box;
    font-family: sans-serif;
    color: white;
}

html {
    // scroll-behavior: smooth;
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
}

body {
    overflow-x: hidden;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    font-size: 62.5%;

    @media screen and (max-width: 75em) {
        font-size: 56.25%;
    }

    @media screen and (max-width: 56.25em) {
        font-size: 50%;
    }

    @media screen and (min-width: 112.5em) {
        font-size: 75%;
    }
}