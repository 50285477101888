.Home {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__image {
            position: relative;
            width: 80%;
            max-width: 100rem;
        }

        p {
            background: linear-gradient(107.16deg,#ec0d78 20.37%,#ff2b40 44.58%,#f75735 66.71%,#fe880e 86.77%,#fe880e 0);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: -.035em!important;
            font-family: "Poppins", sans-serif;
            text-align: center;
            margin-top: 2rem;
            // animation: hue 15s infinite alternate;
            font-size: 1rem;

            @media screen and (min-width: 319px) { font-size: 1.25rem }

            @media screen and (min-width: 374px) { font-size: 1.5rem }

            @media screen and (min-width: 475px) { font-size: 2rem }

            @media screen and (min-width: 620px) { font-size: 2.25rem }

            @media screen and (min-width: 750px) { font-size: 2.5rem }

            @media screen and (min-width: 850px) { font-size: 3rem }

            @media screen and (min-width: 1100px) { font-size: 3.5rem }

            @media screen and (min-width: 1250px) { font-size: 4rem }

            @media screen and (min-width: 1400px) { font-size: 4.5rem }

            @media screen and (min-width: 1600px) { font-size: 5rem }
        }

        button {
            background-color: transparent;
            transition: transform 0.2s;
            cursor: pointer;
            margin-top: 2rem;

            &:hover {
                transform: scale(1.05);
            }

            svg {
                fill: gray;
                height: 3.5rem;
                width: 3.5rem;
            }
        }
    }

    &__main {
        width: 100%;
        max-width: 1280px;

        h2 {
            text-align: right;
            padding-right: 1rem;

            // span {
            //     animation: blink 0.5s infinite alternate;
            // }
        }

        .gradient {
            background: linear-gradient(107.16deg,#ec0d78 20.37%,#ff2b40 44.58%,#f75735 66.71%,#fe880e 86.77%,#fe880e 0);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: -0.035rem;
            font-family: "Poppins", sans-serif;
        }

        section {
            min-height: 50vh;
            width: 100%;
            padding: 3rem 0;
        }

        &__about-us {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                text-align: center;
                font-size: 2rem;

                @media screen and (min-width: 319px) { font-size: 2.25rem }

                @media screen and (min-width: 374px) { font-size: 2.5rem }

                @media screen and (min-width: 475px) { font-size: 3rem }

                @media screen and (min-width: 620px) { font-size: 3.25rem }

                @media screen and (min-width: 750px) { font-size: 3.5rem }

                @media screen and (min-width: 850px) { font-size: 3.75rem }

                @media screen and (min-width: 1100px) { font-size: 4rem }

                @media screen and (min-width: 1250px) { font-size: 4.25rem }

                @media screen and (min-width: 1400px) { font-size: 4.5rem }

                @media screen and (min-width: 1600px) { font-size: 5rem }
            }
        }

        &__our-projects {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: flex-start;

            &__project {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90%;
                max-width: 20rem;

                &__image {
                    position: relative;
                    width: 7.5rem;
                }

                h3 {
                    font-size: 2rem;

                    @media screen and (min-width: 1600px) { font-size: 2.25rem }
                }

                p {
                    font-family: "Poppins", sans-serif;
                    color: gray;
                    text-align: center;
                    font-size: 1rem;

                    @media screen and (min-width: 1600px) { font-size: 1.25rem }
                }
            }
        }

        &__services {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            ul {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                text-align: center;

                @media screen and (min-width: 712px) { text-align: left; }

                li {
                    font-family: "Poppins", sans-serif;
                    color: gray;
                    font-size: 1.5rem;

                    @media screen and (min-width: 1600px) { font-size: 2.25rem }
                }

                li:first-child {
                    color: white;
                    font-size: 2.5rem;

                    @media screen and (min-width: 1600px) { font-size: 3rem }
                }
            }
        }

        &__client-projects {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}