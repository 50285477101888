.SocialLinks {
    width: 100%;
    min-height: 1.5rem;
    margin: 2rem 0;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    direction: ltr;

    &.intersected--true &__icon {
        animation: fadeIn 0.5s forwards;

        &--facebook {
            animation-delay: 0.15s;
        }

        &--twitter {
            animation-delay: 0.3s;
        }

        &--instagram {
            animation-delay: 0.45s;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.5rem;
        border-radius: 0.1rem;
        overflow: hidden;
        opacity: 0;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: white;
        }

        &--facebook svg:hover {
            fill: #1877f2;
        }

        &--twitter svg:hover {
            fill: #1da1f2;
        }

        &--instagram svg:hover {
            fill: url(#instagram-gradient);
        }
    }
}