@import "mixins/fadeInTranslateUpScaleUp";

.Heading {
    @include fadeInTranslateUpScaleUpMixin;
    width: 100%;
    max-width: 700px;
    margin-top: 2rem;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 2.25rem;

    @media screen and (min-width: 375px) {
        font-size: 2.35rem;
    }

    @media screen and (min-width: 400px) {
        font-size: 2.75rem;
    }

    @media screen and (min-width: 600px) {
        font-size: 3rem;
    }

    @media screen and (min-width: 800px) {
        font-size: 3.5rem;
    }
}