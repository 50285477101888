.Home__post {
    position: relative;
    width: 47.5%;
    padding-bottom: 80%;
    margin-top: 2.5%;
    border-radius: 0.2rem;
    overflow: hidden;
    
    @media screen and (min-width: 550px) {
        width: 30%;
        padding-bottom: 53.33%;
    }
}