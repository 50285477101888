.Logo {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 100%;
    padding: 1rem;

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        visibility: hidden;
        pointer-events: none;
    }

    &--absolute, &--fixed {
        z-index: 7;
    }

    &--absolute {
        position: absolute;
        top: 0;
        left: 2.5%;

        @media screen and (min-width: 768px) {
            left: 1.5%;
        }
    }

    &--fixed {
        position: fixed;
        top: 2rem;
        left: 2rem;
    }
}