@import "mixins/fadeIn";

.Img__lazy {
    @include fadeInMixin;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: blur(1.5rem);
        transform: scale(1.35);
    }
}