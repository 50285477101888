.Quote__author-image__background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    stroke: url(#quote-image-background-gradient);
    stroke-width: 0.75rem;

    circle {
        transform: translate(50%, 75%) scale(1);

        @media screen and (min-width: 250px) {
            transform: translate(50%, 75%) scale(1.3);
        }
    }
}