@import "mixins/fadeInTranslateUpScaleUp";

.PreformattedCode {
    @include fadeInTranslateUpScaleUpMixin;
    width: 100%;
    max-width: 700px;
    margin-top: 2rem;
    direction: ltr;

    &__code {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
        word-spacing: 0.5rem;
        overflow-x: auto;
        font-family: monospace;

        &.mac {
            font-family: "SF Mono", Monaco, Menlo, Courier, monospace;
        }

        &.linux {
            font-family: "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", monospace;
        }

        &.windows {
            font-family: Consolas, "Courier New", monospace;
        }

        &__whitespace {
            background-image: url(https://res.cloudinary.com/different-network-cdn/image/upload/v1583676460/Static/dot_wqlc04.png);
            background-repeat: no-repeat;
            background-position: center 62.5%;
        }
    }
}