.PageLoading {
    width: 100%;
    height: 100vh;

    &__loader {
        position: absolute;
        z-index: 5;
        height: 2.5rem;
        top: 50%;
        left: 50%;
    }
}