@import "mixins/fadeInTranslateUpScaleUp";

.Figure {
    @include fadeInTranslateUpScaleUpMixin;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}