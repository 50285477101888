.Home__post__content__type {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0 2.5%;
    color: white;

    span {
        border-radius: 0.1rem;
        backdrop-filter: blur(1rem);
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }

    &--article span {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--news-article span {
        padding-top: 0;
        padding-bottom: 0.3rem;
    }
}