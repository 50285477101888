@import "mixins/fadeIn";

.Home__post__full {
    @include fadeInMixin;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}