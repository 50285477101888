@mixin keyframesMixin {
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    @keyframes fadeInTranslateUp {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeInTranslateUpScaleUp {
        to {
            opacity: 1;
            transform: translateY(0) scale(1);
        }
    }

    @keyframes animateHueRotate {
        0% {
            filter: hue-rotate(0deg);
        }
    
        100% {
            filter: hue-rotate(360deg);
        }
    }

    @keyframes hue {
        from {
            filter: hue-rotate(0deg);
        }
        to {
            filter: hue-rotate(-135deg);
        }
    }

    // @keyframes blink {
    //     from {
    //         opacity: 0.5;
    //     }
    //     to {
    //         opacity: 0;
    //     }
    // }
}