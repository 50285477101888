.Home__post__content__title {
    color: white;
    padding: 2.5%;
    font-size: 6.5vw;

    @media screen and (min-width: 550px) {
        font-size: 4vw;
    }


    @media screen and (min-width: 1280px) {
        font-size: 3.2rem;
    }
}