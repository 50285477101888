@import "mixins/fadeInTranslateUpScaleUp";

.TableOfContents {
    @include fadeInTranslateUpScaleUpMixin;
    width: 100%;
    max-width: 700px;
    list-style: none;
    margin-top: 2rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
        width: 90%;

        a {
            font-size: 1.75rem;
            font-weight: 600;
            text-decoration: none;
            text-align: center;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}