@mixin fadeInMixin($condition: true) {
    opacity: 0;

    @if $condition {
        &.intersected--true, &.loaded--true {
            animation: fadeIn 0.5s forwards;
        }
    }
    @else {
        animation: fadeIn 0.5s forwards;
    }
}