.Article__header__title {
    position: absolute;
    z-index: 6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;

    h1 {
        width: 99%;
        font-size: 3rem;
        margin-left: 1.5rem;
        margin-bottom: 5rem;

        @media screen and (min-width: 375px) {
            font-size: 3.5rem;
        }

        @media screen and (min-width: 425px) {
            font-size: 4rem;
        }

        @media screen and (min-width: 768px) {
            width: 90%;
        }

        @media screen and (min-width: 1024px) {
            width: 80%;
        }

        @media screen and (min-width: 1440px) {
            width: 65%;
            font-size: 4.25rem;
        }

        @media screen and (min-width: 1920px) {
            width: 60%;
            font-size: 5rem;
        }
    }
}