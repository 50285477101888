@import "mixins/fadeIn";

.Video__lazy {
    @include fadeInMixin;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}