.PageNotFound {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 4rem;
        color: #ccc;
    }

    a {
        font-size: 3rem;
        text-align: center;
        color: white;
    }
}