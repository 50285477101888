.Article__main--object-observer-fix {
    height: 1px;
}

.Article__main {
    // Normal
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // Columns vertical flow.
    // width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-evenly;
    // align-items: center;


    // Columns horizontal flow.
    // height: 100vh;
    // overflow-x: auto;
    // min-width: 100%;
    // column-width: 700px;
    // column-fill: auto;
    
    // .Figure, .Quote {
    //     break-inside: avoid;
    // }
}