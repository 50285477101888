@import "mixins/fadeIn";

.Img__full {
    @include fadeInMixin;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}