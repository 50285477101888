.Home__post__lazy {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}